<template>
  <div id="task-index">
    <div class="route border-right-black">
      <div class="uploading">
        <Button type="primary"
                @click="upload"
                :style="{visibility:(SelectedInd >= 4||SelectedInd==0 ?'hidden':'visible')}">上传</Button>
      </div>
      <div class="uploading">
        <Button type="primary"
                @click="iconModal = true">图标管理</Button>
      </div>
      <p class="route-title">成果</p>
      <router-link v-for="(item, ind) in all_navBar"
                   :key="ind"
                   :to="item.path"><span :id="SelectedInd == ind ? 'Selected' : ''"
              @click="Selected(ind)">
          <img :src="SelectedInd == ind?item.img_c:item.img"
               alt="" />
          {{ item.name }}</span></router-link>
    </div>
    <div class="conent">
      <router-view></router-view>
    </div>
    <!-- 图标弹窗 -->
    <Modal v-model="iconModal">
      <p slot="header">
        <span>标注图标管理</span>
        <Tooltip max-width="200">
          <Icon type="md-help-circle" />

          <div slot="content">
            <p>
              标注图标用于二维地图和全景图的标注。为避免出现显示异常问题，建议上传图片文件尺寸为32*32像素。
            </p>
            <!-- <p><i>obgs：较为通用的格式，注意Production_2</i></p> -->
          </div>
        </Tooltip>

      </p>
      <div class="markiconList">
        <div v-for="item in markImgList"
             :key="item.id">
          <img :src="item.path" />
          <Icon type="ios-close"
                class="delIcon"
                style="cursor: pointer;"
                size="36"
                @click="iconRemove(item.id)"></Icon>
        </div>
        <Upload :data="mediaData"
                :headers="{ token: token }"
                name="multipartFiles"
                :action="iconUrl"
                :format="mediaFormat"
                :on-format-error="handleFormatError"
                :on-success="addMediaSuccess"
                :on-error="addMediaError"
                :show-upload-list="false"
                style="display: inline-block; width: 70px">
          <div class="camera">
            <Icon type="ios-camera"
                  size="20"></Icon>
          </div>
        </Upload>
      </div>
    </Modal>
  </div>
</template>

<script>
// import shapan from "@/assets/img/statistics/xf_shapan.png";
// import quanjing from "@/assets/img/statistics/xf_quanjing.png";
// import meiti from "@/assets/img/statistics/xf_meiti.png";
// import pintu from "@/assets/img/statistics/xf_pintu.png";
// import shapan_c from "@/assets/img/statistics/xf_shapan_c.png";
// import quanjing_c from "@/assets/img/statistics/xf_quanjing_c.png";
// import meiti_c from "@/assets/img/statistics/xf_meiti_c.png";
// import pintu_c from "@/assets/img/statistics/xf_pintu_c.png";

// import shapan from "@/assets/img/statistics/xf_shapan.png";
import all from '@/assets/img/statistics/all.png';
import swmx from '@/assets/img/statistics/swmx.png';
import ewdt from '@/assets/img/statistics/ewdt.png';
import quanjing from '@/assets/img/statistics/qjzp.png';
import meiti from '@/assets/img/statistics/zpsp.png';
import pintu from '@/assets/img/statistics/kspt.png';
// import shapan_c from "@/assets/img/statistics/xf_shapan_c.png";
import swmx_c from '@/assets/img/statistics/swmx_c.png';
import all_c from '@/assets/img/statistics/all_c.png';
import ewdt_c from '@/assets/img/statistics/ewdt_c.png';
import quanjing_c from '@/assets/img/statistics/qjzp_c.png';
import meiti_c from '@/assets/img/statistics/zpsp_c.png';
import pintu_c from '@/assets/img/statistics/kspt_c.png';
import dtbz from '@/assets/img/statistics/dtbz.png';
import dtbz_c from '@/assets/img/statistics/dtbz_c.png';
import {
  UploadFile,
  AddModelIcon,
  ModelIconList,
  DelModelIcon,
} from '@/utils/javaApi.js';

export default {
  data() {
    return {
      all_navBar: [
        {
          name: '总览',
          path: '/overview',
          img: all,
          img_c: all_c,
        },
        {
          name: '三维模型',
          path: '/index/resource/modal',
          img: swmx,
          img_c: swmx_c,
        },
        {
          name: '二维地图',
          path: '/index/resource/ermodal',
          img: ewdt,
          img_c: ewdt_c,
        },
        {
          name: '全景照片',
          path: '/index/resource/panorama',
          img: quanjing,
          img_c: quanjing_c,
        },
        {
          name: '照片视频',
          path: '/index/resource/media',
          img: meiti,
          img_c: meiti_c,
        },
        {
          name: '快速拼图',
          path: '/index/resource/rapidMap',
          img: pintu,
          img_c: pintu_c,
        },
      ],
      SelectedInd: 0,
      token: '',
      iconUrl: '',
      iconModal: false,
      markImgList: [],
      mediaFormat: ['jpg', 'png', 'jpeg'],
      mediaData: { teamId: 1, isShare: '2', type: '2d' },
    };
  },
  mounted() {
    let activePath = this.$route.path;
    this.token = sessionStorage.getItem('token');
    if (this.$CSTJ) {
      this.all_navBar.push({
        name: '标注成果',
        path: '/index/resource/markList',
        img: dtbz,
        img_c: dtbz_c,
      });
    }
    this.iconUrl = AddModelIcon();
    this.all_navBar.forEach((item, ind) => {
      if (item.path == activePath) {
        this.Selected(ind);
      }
    });

    this.getIconList();
  },
  methods: {
    //选中nav
    Selected(ind) {
      console.log('11', ind);
      this.SelectedInd = ind;
    },
    // 上传事件
    upload() {
      switch (this.SelectedInd) {
        case 1:
          this.$store.commit('changeFileType', 1);
          this.$_bus.$emit('addModalshow');
          break;
        case 2:
          this.$store.commit('changeFileType', 2);
          this.$_bus.$emit('addModalshow');
          break;
        case 3:
          this.$_bus.$emit('addPanorma');
          break;
      }
    },
    /**
     * 图标管理
     * 1.图标列表
     * 2.上传图标
     * 3.删除图标
     */
    // 1.图标列表
    getIconList() {
      this.$post(ModelIconList(), {
        team_id: 1,
        type: '2d',
        is_share: 2,
      }).then((res) => {
        this.markImgList = res.data.cloud_image_list;
      });
    },
    // 2.上传图标
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '请选择图片格式文件',
      });
    },
    addMediaError(error) {
      this.$Message.error(error);
    },
    addMediaSuccess(response) {
      if (response.code === 1) {
        this.$Message.success('上传成功');
        this.getIconList();
      } else {
        this.$Message.error(response.msg_customer);
      }
    },
    // 3.删除图标
    iconRemove(id) {
      this.$Modal.confirm({
        title: '确认是否删除该图标?',
        content: '<p>删除后使用了该图片的标注将显示异常</p>',
        loading: true,
        onOk: () => {
          this.$post(DelModelIcon(), {
            ids: JSON.stringify(id),
          }).then((res) => {
            if (res.code === 1) {
              this.$Message.success(res.msg_customer);
              this.$Modal.remove();
              this.getIconList();
            } else {
              this.$Message.error(res.msg_customer);
            }
          });
        },
        onCancel: () => {},
      });
    },
  },
};
</script>

<style  scoped lang="scss">
#task-index {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  padding-bottom: 0;
  background-color: $xf_hei2;
  color: $white;
  display: flex;
  .route {
    width: 160px;
    // height: 100px;
    background-color: $xf_hui1;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    .uploading {
      padding: 10px 20px;
      button {
        width: 100%;
      }
    }
    .route-title {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
      background-color: $xf_hui5;
      color: $font_color_1;
    }
    a {
      display: inline-block;
      // width: 104px;
      // color: #4c98ff;
      color: #888;
      span {
        // display: block;
        display: flex;
        align-items: center;
        padding: 15px 10px;
        text-align: center;
        img {
          width: 15px;
          margin: 5px;
        }
      }
    }
    #Selected {
      background: $xf_hui1;
      // color: #fff;
      color: #4c98ff;
    }
  }
  .conent {
    width: calc(100% - 160px);
    height: 100%;
    background-color: $xf_hei2;
  }
}
.markiconList {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 70px;
    height: 70px;
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;
    border: 1px solid #ddd;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    .delIcon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 15px;
      left: 15px;
      color: #fff !important;
      display: none;
    }
  }
  div:hover {
    background-color: rgba(0, 0, 0, 0.4);
    .delIcon {
      display: block;
    }
  }
  .camera {
    width: 70px;
    line-height: 70px;
    text-align: center;
    cursor: pointer;
  }
}
</style>